var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("topOperatingButton", {
        attrs: {
          isSubmitAddBtn: false,
          isAuditBillBtn: false,
          isQuitBtn: false,
        },
        on: { submitForm: _vm.submitForm },
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "门店要货参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marB10" },
                    [
                      _c("span", [_vm._v("门店订货选择生产班次")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form
                              .shop_REQUIRE_GOODS_CONFIG_CHOOSE_PRODUCE_SHIFT_61,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_CHOOSE_PRODUCE_SHIFT_61",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_CHOOSE_PRODUCE_SHIFT_61",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("span", [_vm._v("要货统计")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_COUNT_11",
                              $$v
                            )
                          },
                          expression: "form.shop_REQUIRE_GOODS_CONFIG_COUNT_11",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("日平均统计周期")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: {
                          min: 1,
                          size: "mini",
                          type: "number",
                          disabled:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== "Y",
                        },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_12,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_COUNT_12",
                              $$v
                            )
                          },
                          expression: "form.shop_REQUIRE_GOODS_CONFIG_COUNT_12",
                        },
                      }),
                      _c("span", [_vm._v("天")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "x-f" }, [
                    _c("span", { staticClass: "marR5" }, [
                      _vm._v("日平均统计开始日期"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-date-picker",
                          {
                            attrs: {
                              size: "mini",
                              type: "date",
                              "picker-options": _vm.pickerOptions,
                              "popper-class": _vm.datePickerClass,
                              disabled:
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !==
                                "Y",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: {
                              mouseenter: function ($event) {
                                _vm.showDatePickerIcon = true
                              },
                              mouseleave: function ($event) {
                                _vm.showDatePickerIcon = false
                              },
                            },
                            model: {
                              value:
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_13,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "shop_REQUIRE_GOODS_CONFIG_COUNT_13",
                                  $$v
                                )
                              },
                              expression:
                                "form.shop_REQUIRE_GOODS_CONFIG_COUNT_13",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showDatePickerIcon,
                                    expression: "showDatePickerIcon",
                                  },
                                ],
                                staticClass: "el-icon-date",
                              }),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("默认备货天数")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: {
                          min: 1,
                          size: "mini",
                          type: "number",
                          disabled:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== "Y",
                        },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_14,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_COUNT_14",
                              $$v
                            )
                          },
                          expression: "form.shop_REQUIRE_GOODS_CONFIG_COUNT_14",
                        },
                      }),
                      _c("span", [_vm._v("天")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("默认备货系数")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: {
                          min: 1,
                          size: "mini",
                          type: "number",
                          disabled:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== "Y",
                        },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_15,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_COUNT_15",
                              $$v
                            )
                          },
                          expression: "form.shop_REQUIRE_GOODS_CONFIG_COUNT_15",
                        },
                      }),
                      _c("span", [_vm._v("倍")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("默认要货周期")]),
                      _c("el-input", {
                        staticClass: "inputWidth underline-input",
                        attrs: {
                          min: 1,
                          size: "mini",
                          type: "number",
                          disabled:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== "Y",
                        },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_16,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_COUNT_16",
                              $$v
                            )
                          },
                          expression: "form.shop_REQUIRE_GOODS_CONFIG_COUNT_16",
                        },
                      }),
                      _c("span", [_vm._v("天(从完成配货到配送到门店)")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marT15 marB15" },
                    [
                      _c("div", { staticClass: "marB5" }, [
                        _vm._v("默认要货策略:"),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "y-start marT15",
                          attrs: {
                            disabled:
                              _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !==
                              "Y",
                          },
                          model: {
                            value: _vm.form.shop_REQUIRE_GOODS_CONFIG_COUNT_17,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "shop_REQUIRE_GOODS_CONFIG_COUNT_17",
                                $$v
                              )
                            },
                            expression:
                              "form.shop_REQUIRE_GOODS_CONFIG_COUNT_17",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(
                              "安全库存(策略公式计算:(安全库存*可用库存数量)*系数)"
                            ),
                          ]),
                          _c(
                            "el-radio",
                            { staticClass: "marT15", attrs: { label: 1 } },
                            [
                              _vm._v(
                                "最低库存(策略公式计算:(安全库存*可用库存数量)*系数)"
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "marT15", attrs: { label: 2 } },
                            [
                              _vm._v(
                                "最高库存(策略公式计算:(安全库存*可用库存数量)*系数)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "订货数自动生成规则" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15 y-start" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("门店引入调拨单控制规则")]),
                        _c("el-tooltip", {
                          staticClass: "item marL20",
                          attrs: {
                            effect: "dark",
                            content: _vm.content1,
                            placement: "top",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.comeRules },
                            model: {
                              value:
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_21
                                  .ruleType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_21,
                                  "ruleType",
                                  $$v
                                )
                              },
                              expression:
                                "form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("不控制"),
                            ]),
                            _c(
                              "el-radio",
                              { attrs: { label: "2" } },
                              [
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_21
                                  .ruleType != 2
                                  ? _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        type: "number",
                                        min: 1,
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleType !== "2",
                                      },
                                    })
                                  : _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        type: "number",
                                        min: 1,
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleType !== "2",
                                      },
                                      model: {
                                        value:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form
                                              .shop_REQUIRE_GOODS_CONFIG_RULE_21,
                                            "ruleValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue",
                                      },
                                    }),
                                _vm._v(" 天后检查并提醒"),
                              ],
                              1
                            ),
                            _c(
                              "el-radio",
                              { attrs: { label: "3" } },
                              [
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_21
                                  .ruleType != 3
                                  ? _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        type: "number",
                                        min: 1,
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleType !== "3",
                                      },
                                    })
                                  : _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        type: "number",
                                        min: 1,
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleType !== "3",
                                      },
                                      model: {
                                        value:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_21
                                            .ruleValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form
                                              .shop_REQUIRE_GOODS_CONFIG_RULE_21,
                                            "ruleValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue",
                                      },
                                    }),
                                _vm._v(" 天后严格控制必须引用"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", [_vm._v("门店调出控制规则")]),
                        _c("el-tooltip", {
                          staticClass: "item marL20",
                          attrs: {
                            effect: "dark",
                            content: _vm.content1,
                            placement: "top",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.controlRules },
                            model: {
                              value:
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_22
                                  .ruleType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_22,
                                  "ruleType",
                                  $$v
                                )
                              },
                              expression:
                                "form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("不控制"),
                            ]),
                            _c(
                              "el-radio",
                              { attrs: { label: "2" } },
                              [
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_22
                                  .ruleType != 2
                                  ? _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        min: 1,
                                        type: "number",
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleType !== "2",
                                      },
                                    })
                                  : _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        min: 1,
                                        type: "number",
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleType !== "2",
                                      },
                                      model: {
                                        value:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form
                                              .shop_REQUIRE_GOODS_CONFIG_RULE_22,
                                            "ruleValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue",
                                      },
                                    }),
                                _vm._v(" 天后检查并提醒"),
                              ],
                              1
                            ),
                            _c(
                              "el-radio",
                              { attrs: { label: "3" } },
                              [
                                _vm.form.shop_REQUIRE_GOODS_CONFIG_RULE_22
                                  .ruleType != 3
                                  ? _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        min: 1,
                                        type: "number",
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleType !== "3",
                                      },
                                    })
                                  : _c("el-input", {
                                      staticClass: "input",
                                      staticStyle: { width: "60px" },
                                      attrs: {
                                        size: "mini",
                                        min: 1,
                                        type: "number",
                                        disabled:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleType !== "3",
                                      },
                                      model: {
                                        value:
                                          _vm.form
                                            .shop_REQUIRE_GOODS_CONFIG_RULE_22
                                            .ruleValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form
                                              .shop_REQUIRE_GOODS_CONFIG_RULE_22,
                                            "ruleValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue",
                                      },
                                    }),
                                _vm._v(" 天后严格控制必须引用"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "门店盘点" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("盘点时默认盘点数为账面数")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_32,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_INVENTORY_32",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_32",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("门店盘点单打印数量为0物料")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_33,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_INVENTORY_33",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_33",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("允许门店修改调入单")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_34,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_INVENTORY_34",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_34",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("门店生产自动扣减原料")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.form.shop_REQUIRE_GOODS_CONFIG_PRODUCE_51,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_PRODUCE_51",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_PRODUCE_51",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f marT15 marB15" },
                    [
                      _c("span", [_vm._v("门店采购不显示单价")]),
                      _c("el-switch", {
                        staticClass: "marL20",
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value:
                            _vm.form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_35,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "shop_REQUIRE_GOODS_CONFIG_INVENTORY_35",
                              $$v
                            )
                          },
                          expression:
                            "form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_35",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-form",
        [
          _c("cardTitleCom", {
            staticStyle: { color: "#333333" },
            attrs: { cardTitle: "收银开单检查库存" },
            scopedSlots: _vm._u([
              {
                key: "cardContent",
                fn: function () {
                  return [
                    _c("div", { staticClass: "marL15" }, [
                      _c("div", { staticClass: "goods1" }, [
                        _c(
                          "div",
                          { staticClass: "top" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "适用商品",
                                  prop: "form.shop_REQUIRE_GOODS_CONFIG_BILLING_41",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.goodsRangeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "goodsRangeType",
                                          $$v
                                        )
                                      },
                                      expression: "form.goodsRangeType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.vip_goods_range_type,
                                    function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.value,
                                          attrs: {
                                            label: dict.value,
                                            value: dict.value,
                                          },
                                        },
                                        [_vm._v(_vm._s(dict.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.goodsRangeType === "0" ||
                        _vm.form.goodsRangeType === "1"
                          ? _c(
                              "div",
                              { staticClass: "allGoods" },
                              [
                                _vm.form.promoType === "0"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "x-f marT15",
                                        attrs: {
                                          "label-width": "110px",
                                          label: "全部特价(元)",
                                          prop: "allSpecialPrice",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.form.allSpecialPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "allSpecialPrice",
                                                $$v
                                              )
                                            },
                                            expression: "form.allSpecialPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.form.promoType === "4"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "x-f marT15",
                                        attrs: {
                                          "label-width": "110px",
                                          label: "全部折扣(%)",
                                          prop: "allSpecialDiscount",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.form.allSpecialDiscount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "allSpecialDiscount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.allSpecialDiscount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.goodsRangeType === "1" ||
                        _vm.form.goodsRangeType === "2"
                          ? _c(
                              "div",
                              { staticClass: "table" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "btn x-f" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: { click: _vm.openSelectGoods },
                                      },
                                      [_vm._v("选择商品 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: { click: _vm.openSelectCategory },
                                      },
                                      [_vm._v("选择类别 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: { click: _vm.openSelectBrand },
                                      },
                                      [_vm._v("选择品牌 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("Ttable", {
                                  attrs: { tableData: _vm.form.goodsItem },
                                  on: {
                                    delTableRow: function ($event) {
                                      return _vm.delTableRow($event, "goods")
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.form.promoType === "0" ||
                                      _vm.form.promoType === "4"
                                        ? {
                                            key: "discountPrice",
                                            fn: function () {
                                              return [
                                                _vm.form.promoType === "0"
                                                  ? _c(
                                                      "el-table-column",
                                                      {
                                                        attrs: {
                                                          label: "特价(元)",
                                                          align: "center",
                                                          width: "180",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                scope
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .promoPrice,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope.row,
                                                                              "promoPrice",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "scope.row.promoPrice",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1128951595
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "header" },
                                                          [
                                                            _c(
                                                              "i",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#ff4949",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                            _vm._v(
                                                              " 特价(元) "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _vm.form.promoType === "4"
                                                  ? _c(
                                                      "el-table-column",
                                                      {
                                                        attrs: {
                                                          label: "折扣(%)",
                                                          align: "center",
                                                          width: "180",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                scope
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .discount,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope.row,
                                                                              "discount",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "scope.row.discount",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          583480212
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "header" },
                                                          [
                                                            _c(
                                                              "i",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#ff4949",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                            _vm._v(" 折扣(%) "),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "marB15" },
                        [
                          _c("span", [_vm._v("POS开单设置")]),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item marL5",
                              attrs: {
                                effect: "dark",
                                content: _vm.content1,
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "marT10" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .shop_REQUIRE_GOODS_CONFIG_BILLING_42,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "shop_REQUIRE_GOODS_CONFIG_BILLING_42",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.shop_REQUIRE_GOODS_CONFIG_BILLING_42",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("不提示且允许开单"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("提示且允许开单"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("提示且不允许开单"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item marL5",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.content2,
                                      placement: "top",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("SelectGoods", {
        attrs: { OpenGoods: _vm.OpenGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodsIds: function ($event) {
            return _vm.getPitchData($event, "goodsItem")
          },
        },
      }),
      _c("selectBrand", {
        attrs: { OpenBrand: _vm.OpenBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.OpenBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.OpenBrand = $event
          },
          brandList: function ($event) {
            return _vm.getPitchData($event, "goodsBrand")
          },
        },
      }),
      _c("selectCategory", {
        attrs: { OpenCategory: _vm.OpenCategory },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.OpenCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.OpenCategory = $event
          },
          getCategoryData: function ($event) {
            return _vm.getPitchData($event, "goodsCategory")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }