<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage"; //表格页面组件
import { shopHotKeyList, shopHotKeyRemove } from "@/api/shop/setup/hotKey.js";
import { keys } from "@/components/Dialog/components/keycode.js";
export default {
  name: "HotKey",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "HotKey",
        formData: null,
      },
      options: {
        uuid: "60000fcb-d48e-541c-1032-17a7cd889b62",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "收银热键列表",
        getListApi: shopHotKeyList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "hotKeyId",
        //搜索
        search: [
          {
            type: "input",
            label: "",
            tip: "请输入键值功能名称",
            model: "",
            filter: "hotKeyName",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "hotKeyName",
            label: "键值功能",
            type: "link",
            click: "update",
            minWidth: 50,
            Width: 50,
            align: "center",
          },
          {
            prop: "hotKeyValue",
            label: "热键",
            minWidth: 50,
            Width: 50,
            align: "center",
            formatter: (v, row) =>
              v
                ?.split(",")
                .map((item) => keys[item])
                .join("+"),
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
    };
  },
  created() {
    // admin商户才展示(新增/删除)按钮
    if (this.$store.state.user.userinfo.tenantId != 2) {
      this.options.buttons = [];
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑热键功能",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增热键功能",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          try {
            await shopHotKeyRemove(this.options.check.map((x) => x.hotKeyId));
            this.$message.success("删除成功");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
