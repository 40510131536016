<template>
  <div class="wrap">
    <topOperatingButton :isSubmitAddBtn="false" :isAuditBillBtn="false" :isQuitBtn="false" @submitForm="submitForm">
    </topOperatingButton>
    <cardTitleCom cardTitle="门店要货参数">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f marB10">
            <span>门店订货选择生产班次</span>
            <el-switch active-value="Y" inactive-value="N" class="marL20"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_CHOOSE_PRODUCE_SHIFT_61">
            </el-switch>
          </div>
          <div class="x-f">
            <span>要货统计</span>
            <el-switch active-value="Y" inactive-value="N" class="marL20"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11">
            </el-switch>
          </div>

          <div class="x-f marT15 marB15">
            <span>日平均统计周期</span>
            <el-input :min="1" size="mini" type="number" class="inputWidth underline-input"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_12"
              :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'"></el-input>
            <span>天</span>
          </div>

          <div class="x-f">
            <span class="marR5">日平均统计开始日期</span>
            <div class="block">
              <!-- <span class="demonstration">默认</span> -->
              <!--  -->
              <el-date-picker v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_13" size="mini" type="date"
                :picker-options="pickerOptions" :popper-class="datePickerClass" @mouseenter="showDatePickerIcon = true"
                @mouseleave="showDatePickerIcon = false" :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'"
                value-format="yyyy-MM-dd">
                <template slot="prefix">
                  <i class="el-icon-date" v-show="showDatePickerIcon"></i>
                </template>
              </el-date-picker>
            </div>
          </div>

          <div class="x-f marT15 marB15">
            <span>默认备货天数</span>
            <el-input :min="1" size="mini" type="number" class="inputWidth underline-input"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_14"
              :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'"></el-input>
            <span>天</span>
          </div>

          <div class="x-f marT15 marB15">
            <span>默认备货系数</span>
            <el-input :min="1" size="mini" type="number" class="inputWidth underline-input"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_15"
              :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'"></el-input>
            <span>倍</span>
          </div>
          <div class="x-f marT15 marB15">
            <span>默认要货周期</span>
            <el-input :min="1" size="mini" type="number" class="inputWidth underline-input"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_16"
              :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'"></el-input>
            <span>天(从完成配货到配送到门店)</span>
          </div>
          <div class="marT15 marB15">
            <div class="marB5">默认要货策略:</div>

            <el-radio-group v-model="form.shop_REQUIRE_GOODS_CONFIG_COUNT_17"
              :disabled="form.shop_REQUIRE_GOODS_CONFIG_COUNT_11 !== 'Y'" class="y-start marT15">
              <el-radio :label="0">安全库存(策略公式计算:(安全库存*可用库存数量)*系数)</el-radio>
              <el-radio :label="1" class="marT15">最低库存(策略公式计算:(安全库存*可用库存数量)*系数)</el-radio>
              <el-radio :label="2" class="marT15">最高库存(策略公式计算:(安全库存*可用库存数量)*系数)</el-radio>
            </el-radio-group>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="订货数自动生成规则">
      <template #cardContent>
        <div class="padd15 y-start">
          <div>
            <div class="x-f">
              <span>门店引入调拨单控制规则</span>
              <el-tooltip class="item marL20" effect="dark" :content="content1" placement="top">
              </el-tooltip>
            </div>
            <div class="marT10">
              <el-radio-group @change="comeRules" v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType">
                <el-radio label="1">不控制</el-radio>

                <el-radio label="2">
                  <!--   @change="
                      (v) =>
                        (form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue = v)
                    " -->
                  <el-input v-if="form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType != 2" style="width: 60px" size="mini"
                    type="number" class="input" :min="1" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType !== '2'
                    "></el-input>
                  <el-input v-else style="width: 60px" size="mini" type="number" class="input" :min="1"
                    v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType !== '2'
                    "></el-input>

                  天后检查并提醒</el-radio>
                <el-radio label="3">
                  <!--  @change="
                      (v) =>
                        (form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue = v)
                    " -->
                  <el-input v-if="form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType != 3" style="width: 60px" size="mini"
                    type="number" :min="1" class="input" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType !== '3'
                    "></el-input>
                  <el-input v-else style="width: 60px" size="mini" type="number" :min="1" class="input"
                    v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleType !== '3'
                    "></el-input>
                  天后严格控制必须引用</el-radio>
              </el-radio-group>
            </div>
            <div class="x-f">
              <span>门店调出控制规则</span>
              <el-tooltip class="item marL20" effect="dark" :content="content1" placement="top">
              </el-tooltip>
            </div>
            <div class="marT10">
              <!--  @change="radioChange2()" -->
              <el-radio-group v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType" @change="controlRules">
                <el-radio label="1">不控制</el-radio>

                <el-radio label="2">
                  <el-input v-if="form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType != 2" style="width: 60px" size="mini"
                    :min="1" type="number" class="input" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType !== '2'
                    " />
                  <el-input v-else style="width: 60px" size="mini" :min="1" type="number" class="input"
                    v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType !== '2'
                    "></el-input>
                  天后检查并提醒</el-radio>

                <el-radio label="3">
                  <el-input v-if="form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType != 3" style="width: 60px" size="mini"
                    :min="1" type="number" class="input" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType !== '3'
                    " />
                  <el-input v-else style="width: 60px" size="mini" :min="1" type="number" class="input" :disabled="
                      form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleType !== '3'
                    " v-model="form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue"></el-input>
                  <!--  @change="
                      (v) =>
                        (form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue = v)
                    " -->
                  天后严格控制必须引用</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="门店盘点">
      <template #cardContent>
        <div class="padd15">
          <!-- <div class="x-f marT15 marB15">
            <span>门店盘点启用账面数</span>
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_31"
            >
            </el-switch>
          </div> -->
          <div class="x-f marT15 marB15">
            <span>盘点时默认盘点数为账面数</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_32">
            </el-switch>
          </div>
          <div class="x-f marT15 marB15">
            <span>门店盘点单打印数量为0物料</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_33">
            </el-switch>
          </div>
          <div class="x-f marT15 marB15">
            <span>允许门店修改调入单</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_34">
            </el-switch>
          </div>
          <div class="x-f marT15 marB15">
            <span>门店生产自动扣减原料</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_PRODUCE_51">
            </el-switch>
          </div>
          <div class="x-f marT15 marB15">
            <span>门店采购不显示单价</span>
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.shop_REQUIRE_GOODS_CONFIG_INVENTORY_35">
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>

    <el-form>
      <cardTitleCom cardTitle="收银开单检查库存" style="color: #333333">
        <template #cardContent>
          <div class="marL15">
            <div class="goods1">
              <div class="top">
                <el-form-item label="适用商品" prop="form.shop_REQUIRE_GOODS_CONFIG_BILLING_41">
                  <el-radio-group v-model="form.goodsRangeType">
                    <el-radio :label="dict.value" v-for="dict in dict.type.vip_goods_range_type" :key="dict.value"
                      :value="dict.value">{{ dict.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <!-- 选择全部商品/全部商品+排除 特价促销方案显示可输入特价字段 折扣优惠放方案显示可输入折扣字段 -->
              <div class="allGoods" v-if="
                  form.goodsRangeType === '0' || form.goodsRangeType === '1'
                ">
                <el-form-item label-width="110px" label="全部特价(元)" v-if="form.promoType === '0'" class="x-f marT15"
                  prop="allSpecialPrice">
                  <el-input v-model="form.allSpecialPrice"></el-input>
                </el-form-item>
                <el-form-item label-width="110px" label="全部折扣(%)" v-if="form.promoType === '4'" class="x-f marT15"
                  prop="allSpecialDiscount">
                  <el-input v-model="form.allSpecialDiscount"></el-input>
                </el-form-item>
              </div>
              <!-- 商品表格区域 -->
              <div class="table" v-if="
                  form.goodsRangeType === '1' || form.goodsRangeType === '2'
                ">
                <div class="btn x-f">
                  <el-button size="mini" @click="openSelectGoods">选择商品
                  </el-button>
                  <el-button size="mini" @click="openSelectCategory">选择类别
                  </el-button>
                  <el-button size="mini" @click="openSelectBrand">选择品牌
                  </el-button>
                </div>
                <!-- 表格组件 -->
                <Ttable :tableData="form.goodsItem" @delTableRow="delTableRow($event, 'goods')">
                  <!-- 特价方案 显示特价-->
                  <template #discountPrice v-if="form.promoType === '0' || form.promoType === '4'">
                    <el-table-column label="特价(元)" align="center" width="180" v-if="form.promoType === '0'">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        特价(元)
                      </template>
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.promoPrice"></el-input>
                      </template>
                    </el-table-column>
                    <!-- 折扣优惠 显示折扣 -->
                    <el-table-column label="折扣(%)" align="center" width="180" v-if="form.promoType === '4'">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        折扣(%)
                      </template>
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.discount"></el-input>
                      </template>
                    </el-table-column>
                  </template>
                </Ttable>
              </div>
            </div>
            <div class="marB15">
              <span>POS开单设置</span>
              <el-tooltip class="item marL5" effect="dark" :content="content1" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
              <div>
                <!-- <div class="x-f">
                  <el-tooltip
                    class="item marL20"
                    effect="dark"
                    :content="content1"
                    placement="top"
                  >
                  </el-tooltip>
                </div> -->
                <div class="marT10">
                  <el-radio-group v-model="form.shop_REQUIRE_GOODS_CONFIG_BILLING_42">
                    <el-radio label="0">不提示且允许开单</el-radio>
                    <el-radio label="1">提示且允许开单</el-radio>
                    <el-radio label="2">提示且不允许开单</el-radio>
                  </el-radio-group>
                  <el-tooltip class="item marL5" effect="dark" :content="content2" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择商品信息框 -->
    <SelectGoods :OpenGoods.sync="OpenGoods" @getGoodsIds="getPitchData($event, 'goodsItem')" />
    <!-- 品牌 -->
    <selectBrand :OpenBrand.sync="OpenBrand" @brandList="getPitchData($event, 'goodsBrand')" />
    <!-- 商品类别 -->
    <selectCategory :OpenCategory.sync="OpenCategory" @getCategoryData="getPitchData($event, 'goodsCategory')" />

    <!-- 选择商品对话框 -->
    <!-- <selectGoods
      :OpenGoods.sync="OpenGoods"
      @getGoodsIds="getPitchData($event, 'goodsItem')"
      :isRadio="isSelectGoodsRadio"
    ></selectGoods> -->
    <!-- 选择商品类别 -->
    <!-- <selectCategory
      :OpenCategory.sync="OpenCategory"
      @getCategoryData="getPitchData($event, 'goodsCategory')"
    ></selectCategory> -->
    <!-- 选择品牌对话框 -->
    <!-- <selectBrand
      :OpenBrand.sync="OpenBrand"
      @brandList="getPitchData($event, 'goodsBrand')"
    ></selectBrand> -->
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom'
import CButton from '@/views/components/c-button/index.vue' //卡片标题

import Ttable from '@/views/marketing/promo/specialprice/components/t-table.vue' //商品表格组件

import selectCategory from '@/views/marketing/components/selectCategory.vue' //类别组价
import selectBrand from '@/views/components/selectBrand' //品牌组件
import SelectGoods from '@/views/components/SelectGoods/SelectGoods.vue' //商品组件
import {
  shopParamAddOrUpdateAPI,
  shopParamListAPI
} from '@/api/shop/setup/posParam/shopParam'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
export default {
  name: 'shopParam',
  dicts: ['round_off_type', 'vip_goods_range_type'],
  components: {
    cardTitleCom,
    CButton,
    Ttable,
    SelectGoods,
    selectCategory,
    selectBrand,
    topOperatingButton
  },
  data() {
    return {
      value2: '',
      value1: '',
      value3: '',
      value4: '',
      content1: '门店对应仓库开启负库存时不检测库存',
      content2: '离线状态检测不到库存时不允许开单',

      date: '',
      selectedDate: '', // 选中的日期
      datePickerClass: 'custom-date-picker', // 自定义样式类名
      showDatePickerIcon: false, // 控制日期选择符的显示
      //选择商品开关
      OpenGoods: false,
      //选择类别开关
      OpenCategory: false,
      //选择品牌开关
      OpenBrand: false,
      //是否开启选择商品对话框单选
      isSelectGoodsRadio: false,
      form: {
        shop_REQUIRE_GOODS_CONFIG_CHOOSE_PRODUCE_SHIFT_61: 'N',
        shop_REQUIRE_GOODS_CONFIG_BILLING_41: '',
        shop_REQUIRE_GOODS_CONFIG_BILLING_42: '0',
        shop_REQUIRE_GOODS_CONFIG: '',
        shop_REQUIRE_GOODS_CONFIG_COUNT_11: 'N', //要货统计
        shop_REQUIRE_GOODS_CONFIG_INVENTORY_32: false,
        shop_REQUIRE_GOODS_CONFIG_INVENTORY_31: false,
        shop_REQUIRE_GOODS_CONFIG_COUNT_13: '',
        shop_REQUIRE_GOODS_CONFIG_COUNT_12: '',
        shop_REQUIRE_GOODS_CONFIG_COUNT_15: '',

        shop_REQUIRE_GOODS_CONFIG_COUNT_14: '',
        shop_REQUIRE_GOODS_CONFIG_COUNT_17: '',
        shop_REQUIRE_GOODS_CONFIG_INVENTORY_34: false,
        shop_REQUIRE_GOODS_CONFIG_COUNT_16: '',
        // shop_REQUIRE_GOODS_CONFIG_RULE_22: 0,
        shop_REQUIRE_GOODS_CONFIG_RULE_21: {
          ruleType: '1',
          ruleValue: ''
        },
        shop_REQUIRE_GOODS_CONFIG_RULE_22: {
          ruleType: '1',
          ruleValue: ''
        },
        shop_REQUIRE_GOODS_CONFIG_INVENTORY_33: false,
        // mallConfigBody: {
        //   mallConfigurationId: null, // ID
        //   isEnableNoTokeBrowse: false, // 是否无需登录
        //   unitPriceRoundOffType: "", // 单价舍入方式
        //   amountRoundOffType: "", // 金额舍入方式
        //   paymentRoundOffType: "", // 收款舍入方式
        //   isDisableShowStock: false, // 是否展示库存
        //   isShowVirtualStock: false, // 是否展示虚拟库存
        //   inventoryStockTyp: "", // 展示基于进销存的商城可用库存类型
        //   virtualStockDeductTyp: "", // 虚拟库存扣除方式
        //   isShowInventoryStock: false, // 是否展示基于进销存的商城可用库存
        //   isLockOrderStock: false, // 是否商品库存锁库

        //   //基本信息
        //   billId: undefined, //促销方案id
        //   billNo: undefined, //促销方案类型编号
        //   promoType: undefined, //促销方案类型
        //   promoName: undefined, //促销方案名称
        //   promoDate: undefined, //促销日期
        //   begTime: undefined, //促销开始时间
        //   endTime: undefined, //促销结束时间
        //   //适用渠道
        //   AllowOfflineShop: ["0", "1"],
        //   isAllowOfflineShop: false, //适用渠道(线下门店)
        //   isAllowOnlineShop: false, //适用渠道(线上商城)
        //   //活动规则
        //   shopRangeType: "0", //适用门店
        //   shopItem: [], //适用门店表格数据
        //   //发放对象
        //   vipLevelRangeTypeArr: ["0", "3"], //适用会员数组
        //   vipLevelItem: [], //适用会员表格数据
        //   vipBirthdayType: "1", //会员生日时间
        //   useTimeRangeType: "0", //适用时间
        //   specialDateItem: [], //适用时间数据
        //   //参与活动的商品
        goodsRangeType: '0', //适用商品
        goodsItem: [], //适用商品表格数据
        allSpecialPrice: undefined, //商品全部特价
        allSpecialDiscount: undefined //商品全部折扣
        //   billRemark: undefined, //备注
        //   liveStatus: false, // 要货统计默认关闭状态
        // },
        // stockConfigItems: [
        //   {
        //     beginRange: "", // 开始数量
        //     endRange: "", // 结束数量
        //     display: "", // 显示的数量
        //     isDisplayStock: false, // 是否展示库存数量
        //   },
        // ],
        // wxTemplateConfigItems: [
        //   {
        //     isEnable: false,
        //     wxTemplateId: "123",
        //   },
        // ],
      },
      checked: [],
      radio: '',
      // -------------------------
      tableData: [{}],
      input: '', //日平均周天数

      showIconshopdeal: false, // 是否显示详情图标

      highlightedRow: null // 高亮的表格行数据
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    //门店调出规则
    controlRules() {
      this.form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue = undefined
    },
    //门店引入规则
    comeRules() {
      this.form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue = undefined
    },
    radioChange() {
      this.form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue = ''
      this.value1 = 'form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue'
      this.value2 = 'form.shop_REQUIRE_GOODS_CONFIG_RULE_21.ruleValue'
    },
    radioChange2() {
      this.form.shop_REQUIRE_GOODS_CONFIG_RULE_22.ruleValue = ''
      this.value3 = ''
      this.value4 = ''
    },
    async getDetails() {
      try {
        const { data } = await shopParamListAPI()

        Object.keys(data).map(key => {
          if (!data[key]) {
            data[key] = this.form[key]
          }
        })
        // const type1 = ["21", "22"];
        // const type2 = ["17", "18", "110"];
        // const k = "shop_REQUIRE_GOODS_CONFIG_RULE_";
        // Object.keys(data).map((x) => {
        //   const type = x.split("_").pop();
        //   if (type1.includes(type)) {
        //     data[k + type].ruleType = data[k + type]?.ruleType
        //       ? Number(data[k + type].ruleType)
        //       : 1;
        //   } else if (type2.includes(type)) {
        //     data[k + type] = Boolean(
        //       !data[k + type] || (data[k + type] && data[k + type] === "true")
        //     );
        //   }
        // });
        this.form = data
      } catch (error) { }
      // getDetails() {
      //   shopParamListAPI().then((res) => {
      //     console.log("门店详情：", res);
      //     this.form = res.data;

      //   });
    },
    submitForm() {
      shopParamAddOrUpdateAPI(this.form).then(res => {
        // console.log('售后修改成功', res)
        this.$message.success(res.msg)
        this.getDetails()
      })
    },

    row(type, index) {
      if (type == 'push') {
        this.form.stockConfigItems.splice(index + 1, 0, {})
      } else {
        if (this.form.stockConfigItems.length <= 1) return
        this.form.stockConfigItems.splice(index, 1)
      }
    },
    showIcon() {
      this.$nextTick(() => {
        this.$refs.datePicker.$refs.input.$el.querySelector(
          '.el-input__icon'
        ).style.display = 'inline-block'
      })
    },
    hideIcon() {
      this.$refs.datePicker.$refs.input.$el.querySelector(
        '.el-input__icon'
      ).style.display = 'none'
    },
    //打开选择商品对话框   index 主要用于组合方案 标识下标区分某个组合  signGoodsTable主要用于买满赠送方案 标识区分参与活动的商品表格/赠送的商品表格
    openSelectGoods(index, signGoodsTable) {
      //当买满赠送方案时  主要用于买满赠送方案 标识区分参与活动的商品表格/赠送的商品表格
      // if (this.form.promoType === "8") {
      //   this.signGoodsTable = signGoodsTable ? signGoodsTable : undefined;
      // }
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (index) this.signGroupIndex = index
      this.OpenGoods = true
    },
    //打开选择类别对话框
    openSelectCategory(index) {
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (index) this.signGroupIndex = index
      this.OpenCategory = true
    },
    //打开品牌对话框
    openSelectBrand(index) {
      //固定组合方案/动态组合方案/组合优惠方案 标识下标,用来标识从某个组合点出来的商品选择框 后续好赋值
      if (index) this.signGroupIndex = index
      this.OpenBrand = true
    },
    /* 要货策略 */
    displayIcon() {
      // 鼠标移入输入框时显示详情图标
      this.showIconshopdeal = true
    },
    hideIcon() {
      // 鼠标移出输入框时隐藏详情图标
      this.showIconshopdeal = false
    },
    highlightRow() {
      // 点击详情图标高亮表格行
      this.highlightedRow = this.tableData[0] // 这里默认选择第一行
      this.inputValue = this.highlightedRow.name // 将选中行的姓名填入输入框
    },
    getPitchData(data, type) {
      console.log('选中的商品', data)
      let tableData2 = [] //定义一个数组变量 (存放不同表格)
      let id //定义一个变量存放不同id名称
      if (type === 'shop') {
        //门店数据
        tableData2 = this.form.shopCookPrintCaseShopReqs //使用引入类型 数组是栈类型 直接赋值栈的地址不变
        id = 'shopId' //门店id名称
      } else if (
        //(商品/商品类别/商品品牌)
        type === 'shopCookPrintCaseGoodsReqs' ||
        type === 'goodsCategory' ||
        type === 'goodsBrand'
      ) {
        //商品数据
        tableData2 = this.cookPrintForm.shopCookPrintCaseGoodsReqs
        console.log(
          'shopCookPrintCaseGoodsReqs',
          this.cookPrintForm.shopCookPrintCaseGoodsReqs
        )
        id = 'lineId' //(商品/商品类别/商品品牌)公用id
        // console.log("======", this.id);  只是赋值，打印不到数据
      }

      if (tableData2.length >= 1) {
        //当表格数据存在时
        for (let i = 0; tableData2.length > i; i++) {
          data = data.filter(v => {
            //商品表格/会员表格需要添加上类型
            if (type === 'shopCookPrintCaseGoodsReqs') {
              //商品
              v.lineType = 1 //商品表格行统一类型
              v.lineId = v.goodsId //商品表格行统一id
              v.lineNo = v.goodsNo //统一编号字段
              v.lineName = v.goodsName //统一名称字段
            } else if (type === 'goodsCategory') {
              //商品类别
              v.lineType = 3
              v.lineNo = v.categoryNo //字段转换成商品表格一样的字段
              v.lineName = v.categoryName
              v.lineId = v.categoryId
            } else if (type === 'goodsBrand') {
              //商品品牌
              v.lineType = 2
              v.lineNo = v.brandNo //字段转换成商品表格一样的字段
              v.lineName = v.brandName
              v.lineId = v.brandId
            }
            return v[id] !== tableData2[i][id] //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
          })
        }
        tableData2.push(...data) //再追加在表格里
        console.log(this.cookPrintForm.shopCookPrintCaseGoodsReqs)
      } else {
        //当表格数据为空时,直接追加表格里
        data.forEach(v => {
          //商品表格/会员表格需要添加上类型
          if (type === 'shopCookPrintCaseGoodsReqs') {
            //商品
            v.lineType = 1 //商品表格行统一类型
            v.lineId = v.goodsId //商品表格行统一id
            v.lineNo = v.goodsNo //统一编号字段
            v.lineName = v.goodsName //统一名称字段
          } else if (type === 'goodsCategory') {
            //商品类别
            v.lineType = 3
            v.lineNo = v.categoryNo //字段转换成商品表格一样的字段
            v.lineName = v.categoryName
            v.lineId = v.categoryId
          } else if (type === 'goodsBrand') {
            //商品品牌
            v.lineType = 2
            v.lineNo = v.brandNo //字段转换成商品表格一样的字段
            v.lineName = v.brandName
            v.lineId = v.brandId
          }
          tableData2.push(v)
          console.log(this.cookPrintForm.shopCookPrintCaseGoodsReqs)
        })
      }
    }
  },
  computed: {
    pickerOptions() {
      // 配置日期选择器的选项
      return {
        // 其他选项...
        popperOptions: {
          // 配置 popper.js 的选项
          modifiers: {
            preventOverflow: {
              enabled: false // 禁用防止溢出的修饰符，使得日期选择框可以超出父容器边界
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.wrap {
  padding-top: 30px;
}
.fontColor {
  color: #ababab;
}
.underline-input /deep/ .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.custom-date-picker .el-input__icon {
  display: none; /* 初始隐藏图标 */
}

.custom-date-picker:hover .el-input__icon {
  display: inline-block; /* 移入时显示图标 */
}
.custom-date-picker .el-date-picker__editor {
  border: none; /* 移除默认边框 */
  border-bottom: 1px solid #ccc; /* 设置底部边框 */
}

.custom-date-picker .el-input__prefix {
  display: none; /* 隐藏日期选择符 */
}
</style>
